<template>
  <v-dialog v-model="dialogOpen" width="600">
    <template v-slot:activator="{ on: dialogOn }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn v-on="{ ...dialogOn, ...tooltipOn }" icon>
            <v-icon small>fal fa-edit</v-icon>
          </v-btn>
        </template>
        <span>Edit Option List or Add New List</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar dark color="#193264">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon dark @click="dialogOpen = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </template>
          <span>Close this dialog</span>
        </v-tooltip>
        <v-toolbar-title>Option List Editor</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="newListDialog" width="300">
          <template v-slot:activator="{ on: dialogOn }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltipOn }">
                <v-btn v-on="{ ...dialogOn, ...tooltipOn }" icon>
                  <v-icon>fas fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Create a new option list</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>New Option List</v-card-title>
            <v-card-text>
              <v-text-field v-model="newListName" label="Name of option list"></v-text-field>
              <v-select v-model="newListType" :items="[{ text: 'Static List', value: 'static' }, { text: 'Dynamic List (pulls from API service)', value: 'dynamic' }]" label="Type of option list"></v-select>
              <v-select v-model="newListEditRole" :items="newListEditRoleOptions" label="Role for who can edit this list"></v-select>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="newListDialog = false">Cancel</v-btn>
              <v-btn color="success" text @click="saveNewList">Create List</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon to="/forms/admin/options">
              <v-icon>fal fa-external-link-square</v-icon>
            </v-btn>
          </template>
          <span>Go to full option editor. Make sure your form is saved before clicking as any changes will be lost.</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-actions v-if="'value' in selectedList">
        <h2>{{ selectedList.name }}</h2>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="selectedList = {}">
              <v-icon>fal fa-undo</v-icon>
            </v-btn>
          </template>
          <span>Find a different option list</span>
        </v-tooltip>
      </v-card-actions>
      <v-card-text v-else>
        <v-autocomplete v-model="selectedList" :search-input.sync="listFilter" :items="selectOptions" label="Find Option List" item-text="name" return-object>
          <template v-slot:item="{ on, item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small>{{ item.type === 'static' ? item.count + ' Items' : item.service || '--no-service--' }}</v-chip>
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-text v-if="selectedList" style="margin-top:1em;max-height:500px;overflow:auto">
        <v-textarea v-if="editor" v-model="selectedList.details" label="Description of option list" rows="3" auto-grow outlined></v-textarea>
        <div v-else-if="selectedList.details !== ''">
          <h4>Details</h4>
          <p>{{ selectedList.details }}</p>
        </div>
        <template v-if="selectedList.type === 'static'">
          <v-toolbar flat>
            <h4>Options in this list</h4>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="info" small style="margin-left:10px">fas fa-question-circle</v-icon>
              </template>
              <div>Enter the values below that you want to appear in the dropdown list. <ul><li>The basic view includes just a single line of text (what is stored is the same as what the user sees).</li><li>The advanced view lets you set a different stored value than the one they see.</li><li>You can remove entries by clicking the <v-icon color="error">fal fa-times-circle</v-icon> icon</li><li>You can rearrange the entries by dragging and dropping them using the handle on the left.</li></ul></div>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-switch v-model="advancedView" :label="advancedView ? 'Advanced View' : 'Basic View'" hide-details></v-switch>
          </v-toolbar>
          <p v-if="!editor">You do not have edit permission to this list. You can view the list below but cannot make changes.</p>
          <v-list elevation="3" dense>
            <draggable v-model="options" handle=".handle">
              <v-list-item v-for="({ text, value }, index) in options" :key="'option-' + index">
                <v-list-item-action v-if="editor" class="handle">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" style="cursor:grab;margin-top:16px">fas fa-grip-lines</v-icon>
                    </template>
                    <span>Drag this item to put it in a different place in the order.</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-content :style="advancedView ? 'padding-right: 5px' : ''">
                  <v-text-field
                    :value="text"
                    :label="advancedView ? 'Visible Text' : 'Option Text'"
                    :disabled="!editor"
                    hide-details
                    @change="(val) => options.splice(index, 1, { text: val, value })">
                  </v-text-field>
                </v-list-item-content>
                <v-list-item-content v-if="advancedView" style="padding-left: 5px">
                  <v-text-field
                    :value="value"
                    :disabled="!editor"
                    label="Stored Value"
                    hide-details
                    @change="(val) => options.splice(index, 1, { text, value: val })">
                  </v-text-field>
                </v-list-item-content>
                <v-list-item-action v-if="editor">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small icon color="error" style="margin-top:12px;" @click="removeOption(index)">
                        <v-icon>fal fa-times-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Remove Option</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </draggable>
          </v-list>
        </template>
        <template v-else-if="selectedList.type === 'dynamic'">
          <v-text-field v-model="selectedList.service" label="API Service" outlined></v-text-field>
          <v-textarea v-model="selectedList.query" label="Query" outlined></v-textarea>
          <v-row>
            <v-col>
              <v-text-field v-model="selectedList.textField" label="Label Field" outlined></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="selectedList.valueField" label="Value Field" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-list dense style="overflow:auto;max-height:400px">
            <v-list-item v-for="(item, index) in dynamicOptions" :key="'dyn-option-' + index">
              <v-list-item-content>
                <v-list-item-title>{{ item[selectedList.textField] }}</v-list-item-title>
                <v-list-item-subtitle v-if="item[selectedList.valueField] != item[selectedList.textField]">Value when selected: {{ item[selectedList.valueField] }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-card-text>
      <v-card-text v-if="selectedList.type === 'static' && hasDuplicate">
        <v-alert type="error">There is more than one entry with the same visible text. Please correct this before saving.</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialogOpen = false">Close</v-btn>
        <v-btn :disabled="hasDuplicate || !editor" color="success" text @click="save">Save Changes</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="selectedList.type === 'static'" :disabled="!editor" color="success" text @click="options.push({ text: '', value: '' })">
          <v-icon left>fas fa-plus</v-icon>
          Add Option
        </v-btn>
        <v-btn v-if="selectedList.type === 'static'" text @click="selectedList = ''; options = []">Clear</v-btn>
        <v-btn v-else-if="selectedList.type === 'dynamic'" text @click="runTest">Test Query</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
export default {
  props: {
    preselectListId: String
  },
  components: {
    draggable: () => import('vuedraggable')
  },
  setup (props, { root }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const dialogOpen = ref(false)
    const listFilter = ref('')
    const selectOptions = ref([])
    watch([listFilter], () => {
      loadList()
    })
    function loadList () {
      let query = { active: { $in: [null, true] }, $limit: 10, $sort: { name: 1 } }
      if (listFilter.value !== '' && listFilter.value != null) {
        query.name = { $regex: listFilter.value, $options: 'i' }
      }
      root.$feathers.service('forms/select-options').find({ query }).then(({ total, data }) => {
        selectOptions.value = data
      })
    }

    const selectedList = ref({})
    const editor = computed(() => {
      if (selectedList.value.createdBy === user.value.username) return true
      for (let i = 0; i < user.value.roles.length; i++) {
        if (user.value.roles[i] === selectedList.value.editRole) return true
      }
      return false
    })
    const options = ref([])
    const hasDuplicate = computed(() => {
      let hash = {}
      for (const { text } of options.value) {
        if (text in hash) return true
        hash[text] = true
      }
      return false
    })
    const loading = ref(false)

    watch(selectedList, () => {
      if ('options' in selectedList.value) {
        options.value = selectedList.value.options
        for (const { text, value } of selectedList.value.options) {
          if (value != null && text !== value) {
            advancedView.value = true
            break
          }
        }
      } else if ('_id' in selectedList.value || 'value' in selectedList.value) {
        options.value = []
        loading.value = true
        root.$feathers.service('forms/select-options').get(selectedList.value._id || selectedList.value.value).then((data) => {
          options.value = data.options
          for (const { text, value } of data.options) {
            if (value != null && text !== value) {
              advancedView.value = true
              break
            }
          }
          loading.value = false
        })
      }
    })

    function save () {
      selectedList.value.options = options.value.map(({ value, text }) => {
        if (value == null || value === '') return { text, value: text }
        else return { value, text }
      })

      root.$feathers.service('forms/select-options').patch(selectedList.value._id, selectedList.value).then(() => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', text: 'Select Option Updated Successfully', timeout: 6000 })
      })
    }

    const dynamicOptions = ref([])
    function runTest () {
      let query = ''
      try {
        let dt = new Date()
        query = JSON.parse(selectedList.value.query.replace('_now_', dt.toISOString()))
      } catch (err) {
        alert('Error parsing query; make sure it is in proper JSON format.')
        return
      }
      root.$feathers.service(selectedList.value.service).find({ query }).catch((err) => {
        // console.log(err)
        alert(err)
      }).then((data) => {
        if ('data' in data) dynamicOptions.value = data.data
        else dynamicOptions.value = data
      })
    }

    const newListDialog = ref(false)
    const newListName = ref('')
    const newListType = ref('static')
    const newListEditRole = ref('')
    const newListEditRoleOptions = computed(() => root.$store.state.user.roles)
    function saveNewList () {
      let obj = {
        name: newListName.value,
        type: newListType.value,
        details: '',
        editRole: '',
        visibleRoles: []
      }
      if (obj.type === 'dynamic') {
        obj.service = ''
        obj.query = ''
        obj.textField = 'text'
        obj.valueField = 'value'
      } else {
        obj.options = []
      }
      // Check to make sure there is not already a list with the same name (we cannot have 2 lists with the same name)
      root.$feathers.service('forms/select-options').find({ query: { name: obj.name } }).then(({ data, total }) => {
        if (total === 0) {
          root.$feathers.service('forms/select-options').create(obj).then((data) => {
            listFilter.value = newListName.value
            selectOptions.value = [data]
            selectedList.value = {}
            for (const field in data) selectedList.value[field] = data[field]
            newListName.value = ''
            newListType.value = 'static'
            newListEditRole.value = ''
          })
          newListDialog.value = false
        } else {
          alert('There is already a' + (data[0].active === false ? 'n inactive' : '') + ' select list with the same name. The name must be unique, so you must choose a different name.')
        }
      })
    }

    const advancedView = ref(false)
    function removeOption (index) {
      options.value.splice(index, 1)
    }

    onMounted(() => {
      if (props.preselectListId != null && props.preselectListId !== '') {
        root.$feathers.service('forms/select-options').get(props.preselectListId).then((data) => {
          data.value = data._id
          selectOptions.value = [data]
          selectedList.value = data
          listFilter.value = data.name
        })
      }
    })

    return {
      dialogOpen,
      listFilter,
      selectOptions,
      loadList,
      selectedList,
      editor,
      options,
      hasDuplicate,
      loading,
      save,
      dynamicOptions,
      runTest,
      newListDialog,
      newListName,
      newListType,
      newListEditRole,
      newListEditRoleOptions,
      saveNewList,
      advancedView,
      removeOption
    }
  }
}
</script>
